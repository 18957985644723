html {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  font-family: -apple-system, 'Source Sans Pro', sans-serif, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Source Sans Pro', sans-serif, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit; /* 1 */
  font: inherit; /* 2 */
  margin: 0; /* 3 */
}

label {
  color: #2A394A;
}

*, *:before, *:after {
  box-sizing: inherit;
}

svg {
  overflow: visible !important;
}

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
